<template>
  <div>
    <van-popup
      round
      v-model="history"
      @close="close"
      @click-overlay="close"
      style="width: 100vw"
    >
      <van-row style="text-align: left; padding: 10px">
        <van-col :span="16">
          <span @click="sheetShow = true">选择参数</span
          ><van-icon
            name="edit"
            size="16"
            color="#3980fd"
            @click="sheetShow = true"
        /></van-col>
        <van-col :span="8">
          <span @click="showTime = true">选择日期</span>
          <van-icon name="underway-o" @click="showTime = true" />
        </van-col>
        <van-popup
          get-container="body"
          round
          v-model="showTime"
          position="bottom"
          :style="{ height: '40%' }"
        >
          <van-datetime-picker
            @cancel="showTime = false"
            @confirm="getHistory(), (showTime = false)"
            v-model="currentDate"
            type="date"
            title="选择年月日"
            :max-date="maxDate"
          />
        </van-popup>
        <!-- 参数列表 -->
        <van-popup
          get-container="body"
          v-model="sheetShow"
          round
          position="bottom"
          :style="{ height: '50%' }"
        >
          <div
            style="
              width: 100%;
              display: flex;
              height: 30px;
              font-size: 30px !important;
              line-height: 30px;
              justify-content: space-around;
            "
          >
            <div @click="sheetShow = false" style="color: #333">取消</div>
            <div>参数列表</div>
            <div style="color: dodgerblue" @click="confirm">确认</div>
          </div>
          <div style="height: calc(100% - 40px); overflow-y: auto">
            <van-cell
              :title="item.text"
              icon="shop-o"
              v-for="(item, index) in CurveData"
              :key="index"
            >
              <!-- 使用 right-icon 插槽来自定义右侧图标 -->
              <template #right-icon>
                <van-checkbox v-model="item.checked"></van-checkbox>
              </template>
            </van-cell>
          </div>
        </van-popup>
      </van-row>
      <div id="history" style="width: 100%; height: 330px"></div>
    </van-popup>
  </div>
</template>

<script>
let that;
import * as echarts from "echarts";
export default {
  props: ["history"],
  data() {
    return {
      Dropdownpower: "",
      sheetShow: false,
      showTime: false,
      maxDate: new Date(),
      currentDate: new Date(),
      CurveData: [],
      historyData: {
        projectId: this.$route.query.projectId,
        date: "",
        paramTags: [],
        historyType: 1,
      },
    };
  },
  created() {
    that = this;
    this.getParamInfo();
  },
  mounted() {},
  methods: {
    confirm() {
      this.historyData.paramTags = this.CurveData.filter(
        (item) => item.checked
      ).map((it) => it.value);
      this.getHistory();
      this.sheetShow = false;
    },
    close() {
      this.$emit("update:history", false);
    },
    async getParamInfo() {
      const res = await this.$api.project.planParams(this.$route.query.planId);
      if (res.code == 200) {
        this.CurveData = res.data.map((item, index) => ({
          text: item.paramName,
          value: item.paramTag,
          checked: index ? false : true,
        }));
        this.Dropdownpower = this.CurveData[0].text;
      }
    },
    getHistory() {
      this.historyData.date = this.dayjs(that.currentDate).format("YYYY-MM-DD");
      this.$api.energy.getHistoryData(that.historyData).then((res) => {
        that.lodingHistory = false;
        if (res.code == 200) {
          let date = res.data.date;
          let series = [];
          res.data.data?.forEach((item) => {
            let newData = [];
            for (let key in item.stream) {
              newData.push([key, item.stream[key]]);
            }
            let obj = {
              name: item.name,
              type: "line",
              // stack: 'Total',
              data: newData,
            };
            series.push(obj);
          });
          this.$nextTick(() => {
            this.setHistory(series, date);
          });
        }
      });
    },
    setHistory(data, date) {
      let option = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "2%",
          right: "3%",
          bottom: "5%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            dataView: {
              readOnly: true,
              optionToContent: function (opt) {
                var axisData = opt.xAxis[0].data;
                var series = opt.series;
                let nameTitle = "";
                series.forEach((item) => {
                  nameTitle += "<td style='width:180px'>" + item.name + "</td>";
                });
                var table =
                  '<table style="text-align:center"><tbody><tr>' +
                  "<td style='width:180px'>时间</td>" +
                  nameTitle +
                  "</tr>";
                for (var i = 0, l = axisData.length; i < l; i++) {
                  let valList = "";
                  series.forEach((item) => {
                    let newVal = "无";
                    item.data.forEach((it) => {
                      if (it[0] == axisData[i]) {
                        newVal = it[1];
                      }
                    });
                    valList += "<td style='width:180px'>" + newVal + "</td>";
                  });
                  table +=
                    "<tr>" +
                    "<td style='width:180px'>" +
                    axisData[i] +
                    "</td>" +
                    valList;
                  ("</tr>");
                }
                table += "</tbody></table>";
                return table;
              },
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: date,
        },
        yAxis: {
          type: "value",
          // nameLocation: "end",
          // boundaryGap: [0, 0],
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
          },
        ],
        series: data,
      };
      var chartDom = document.getElementById("history");
      let myChart = echarts.init(chartDom);
      myChart.clear();
      myChart.setOption(option);
    },
  },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
</style>
