<template>
  <div
    style="
      padding-top: 50px;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      margin-bottom: 50px;
    "
    class="fadeIn"
  >
    <!-- 参数详情 -->
    <van-nav-bar
      :title="title"
      fixed
      right-text="历史数据"
      @click-right="onClickRight"
      left-arrow
      @click-left="$router.back()"
      z-index="9999"
    />
    <van-row type="flex" justify="space-around">
      <van-row class="Page_heading">
        <div>UA:{{ paramsData.UA }}</div>
        <div>UB:{{ paramsData.UB }}</div>
        <div>UC:{{ paramsData.UC }}</div>
        <div>UAB:{{ paramsData.UAB }}</div>
        <div>UBC:{{ paramsData.UBC }}</div>
        <div>UAC:{{ paramsData.UAC }}</div>
        <div>IA:{{ paramsData.IA }}</div>
        <div>IB:{{ paramsData.IB }}</div>
        <div>IC:{{ paramsData.IC }}</div>
      </van-row>
    </van-row>
    <div style="width: 100%; height: 150px">
      <div class="Panel">
        <div class="Panel_item">
          <span></span>
          <span>电压</span>
          <span>电流</span>
          <span>功率</span>
          <span>温度</span>
        </div>
        <div class="Panel_item" style="">
          <span>极大值</span>
          <span>{{ realData.UMA }}</span>
          <span>{{ realData.IMA }}</span>
          <span>{{ realData.PMA }}</span>
          <span>{{ realData.TMA }}</span>
        </div>
        <div class="Panel_item">
          <span>平均值</span>
          <span>{{ realData.UAV }}</span>
          <span>{{ realData.IAV }}</span>
          <span>{{ realData.PAV }}</span>
          <span>{{ realData.TAV }}</span>
        </div>
        <div class="Panel_item">
          <span>极小值</span>
          <span>{{ realData.UMI }}</span>
          <span>{{ realData.IMI }}</span>
          <span>{{ realData.PMI }}</span>
          <span>{{ realData.TMI }}</span>
        </div>
      </div>
    </div>
    <div
      style="
        width: 100%;
        height: 150px;
        text-align: left;
        padding: 10px;
        font-weight: 700;
      "
    >
      <van-row>
        <van-col :span="20">
          <span>{{ Dropdownpower }}均极值曲线</span
          ><van-icon
            name="edit"
            size="16"
            color="#3980fd"
            @click="sheetShow = true"
        /></van-col>
        <van-col :span="4">
          <van-icon name="underway-o" @click="showTime = true" />
        </van-col>
        <van-popup
          round
          v-model="showTime"
          position="bottom"
          :style="{ height: '40%' }"
        >
          <van-datetime-picker
            @cancel="showTime = false"
            @confirm="getPowerFactor(0)"
            v-model="currentDate"
            type="date"
            title="选择年月日"
            :max-date="maxDate"
          />
        </van-popup>
      </van-row>
      <div id="power" style="width: 100%; height: 130px"></div>
    </div>
    <div class="relative" style="height: 370px">
      <span class="absolute" style="font-weight: 700">功率</span>
      <div
        style="
          height: 50px;
          display: flex;
          color: black;
          font-weight: 700;
          margin-bottom: 40px;
        "
      >
        <div style="width: 80px; height: 100%" class="relative">
          <div
            style="
              width: 80px;
              height: 100%;
              transform: translate(0, 35px) rotate(40deg) rotateX(180deg);
              transform-origin: center center;
            "
          >
            <el-progress
              v-if="progressShow"
              style=""
              color="#f36710"
              type="dashboard"
              :percentage="progressData.A[3] * 100"
              :width="80"
              :stroke-width="6"
              :show-text="false"
            ></el-progress>
          </div>
          <div class="pie_absolute">
            <span class="color_bar1" style="font-size: 20px">{{
              progressData.A[3]
            }}</span>
            <div
              style="
                font-size: 12px !important;
                transform: scale(0.8) translate(0, -5px);
                color: #08ae9e;
              "
            >
              <p>A相</p>
              <span>功率因数</span>
            </div>
          </div>
        </div>
        <div style="padding-top: 35px; padding-left: 10px">
          <span class="circle"></span>
        </div>
        <div style="margin: 0 10px; padding-top: 30px">
          <div style="margin-bottom: 10px">有功功率</div>
          <div>无功功率</div>
        </div>
        <div style="padding-top: 25px" class="relative">
          <el-progress
            v-if="progressShow"
            :class="{ rotate: progressData.A[0] < 0 }"
            :percentage="
              format(Math.abs((progressData.A[0] / progressData.A[2]) * 100))
            "
            color="#f36710"
            :stroke-width="6"
            style="width: 100px; margin-bottom: 22px"
            :show-text="false"
          ></el-progress>
          <el-progress
            v-if="progressShow"
            :class="{ rotate: progressData.A[1] < 0 }"
            :percentage="
              format(Math.abs((progressData.A[1] / progressData.A[2]) * 100))
            "
            color="#f36710"
            :stroke-width="6"
            style="width: 100px"
            :show-text="false"
          ></el-progress>
          <span class="span_absolute color_bar1">{{ progressData.A[0] }}</span>
          <span class="span2_absolute color_bar1">{{ progressData.A[1] }}</span>
        </div>
        <div style="padding-top: 28px; text-align: center">
          <div style="margin-bottom: 10px">视在功率</div>
          <div class="text_color">{{ progressData.A[2] }}</div>
        </div>
      </div>
      <div
        style="
          height: 50px;
          display: flex;
          color: black;
          font-weight: 700;
          margin-bottom: 40px;
        "
      >
        <div style="width: 80px; height: 100%" class="relative">
          <div
            style="
              width: 80px;
              height: 100%;
              transform: translate(0, 35px) rotate(40deg) rotateX(180deg);
              transform-origin: center center;
            "
          >
            <el-progress
              v-if="progressShow"
              style=""
              color="#66c603"
              type="dashboard"
              :percentage="progressData.B[3] * 100"
              :width="80"
              :stroke-width="6"
              :show-text="false"
            ></el-progress>
          </div>
          <div class="pie_absolute">
            <span class="color_bar2" style="font-size: 20px">{{
              progressData.B[3]
            }}</span>
            <div
              style="
                font-size: 12px !important;
                transform: scale(0.8) translate(0, -5px);
                color: #08ae9e;
              "
            >
              <p>B相</p>
              <span>功率因数</span>
            </div>
          </div>
        </div>
        <div style="padding-top: 35px; padding-left: 10px">
          <span class="circle" style="background-color: #66c603"></span>
        </div>
        <div style="margin: 0 10px; padding-top: 30px">
          <div style="margin-bottom: 10px">有功功率</div>
          <div>无功功率</div>
        </div>
        <div style="padding-top: 25px" class="relative">
          <el-progress
            v-if="progressShow"
            :class="{ rotate: progressData.B[0] < 0 }"
            :percentage="
              format(Math.abs((progressData.B[0] / progressData.B[2]) * 100))
            "
            color="#66c603"
            :stroke-width="6"
            style="width: 100px; margin-bottom: 22px"
            :show-text="false"
          ></el-progress>
          <el-progress
            v-if="progressShow"
            :class="{ rotate: progressData.B[1] < 0 }"
            :percentage="
              format(Math.abs((progressData.B[1] / progressData.B[2]) * 100))
            "
            color="#66c603"
            :stroke-width="6"
            style="width: 100px"
            :show-text="false"
          ></el-progress>
          <span class="span_absolute color_bar2">{{ progressData.B[0] }}</span>
          <span class="span2_absolute color_bar2">{{ progressData.B[1] }}</span>
        </div>
        <div style="padding-top: 28px; text-align: center">
          <div style="margin-bottom: 10px">视在功率</div>
          <div class="text_color">{{ progressData.B[2] }}</div>
        </div>
      </div>
      <div
        style="
          height: 50px;
          display: flex;
          color: black;
          font-weight: 700;
          margin-bottom: 40px;
        "
      >
        <div style="width: 80px; height: 100%" class="relative">
          <div
            style="
              width: 80px;
              height: 100%;
              transform: translate(0, 35px) rotate(40deg) rotateX(180deg);
              transform-origin: center center;
            "
          >
            <el-progress
              v-if="progressShow"
              style=""
              color="#fd4848"
              type="dashboard"
              :percentage="progressData.C[3] * 100"
              :width="80"
              :stroke-width="6"
              :show-text="false"
            ></el-progress>
          </div>
          <div class="pie_absolute">
            <span class="color_bar3" style="font-size: 20px">{{
              progressData.C[3]
            }}</span>
            <div
              style="
                font-size: 12px !important;
                transform: scale(0.8) translate(0, -5px);
                color: #08ae9e;
              "
            >
              <p>C相</p>
              <span>功率因数</span>
            </div>
          </div>
        </div>
        <div style="padding-top: 35px; padding-left: 10px">
          <span class="circle" style="background-color: #fd4848"></span>
        </div>
        <div style="margin: 0 10px; padding-top: 30px">
          <div style="margin-bottom: 10px">有功功率</div>
          <div>无功功率</div>
        </div>
        <div style="padding-top: 25px" class="relative">
          <el-progress
            v-if="progressShow"
            :class="{ rotate: progressData.C[0] < 0 }"
            :percentage="
              format(Math.abs((progressData.C[0] / progressData.C[2]) * 100))
            "
            color="#fd4848"
            :stroke-width="6"
            style="width: 100px; margin-bottom: 22px"
            :show-text="false"
          ></el-progress>
          <el-progress
            v-if="progressShow"
            :class="{ rotate: progressData.C[1] < 0 }"
            :percentage="
              format(Math.abs((progressData.C[1] / progressData.C[2]) * 100))
            "
            color="#fd4848"
            :stroke-width="6"
            style="width: 100px"
            :show-text="false"
          ></el-progress>
          <span class="span_absolute color_bar3">{{ progressData.C[0] }}</span>
          <span class="span2_absolute color_bar3">{{ progressData.C[1] }}</span>
        </div>
        <div style="padding-top: 28px; text-align: center">
          <div style="margin-bottom: 10px">视在功率</div>
          <div class="text_color">{{ progressData.C[2] }}</div>
        </div>
      </div>
      <div
        style="
          height: 50px;
          display: flex;
          color: black;
          font-weight: 700;
          margin-bottom: 40px;
        "
      >
        <div style="width: 80px; height: 100%" class="relative">
          <div
            style="
              width: 80px;
              height: 100%;
              transform: translate(0, 35px) rotate(40deg) rotateX(180deg);
              transform-origin: center center;
            "
          >
            <el-progress
              v-if="progressShow"
              style=""
              color="#088fef"
              type="dashboard"
              :percentage="progressData.H[3] * 100"
              :width="80"
              :stroke-width="6"
              :show-text="false"
            ></el-progress>
          </div>
          <div class="pie_absolute">
            <span class="color_bar1" style="font-size: 20px">{{
              progressData.H[3]
            }}</span>
            <div
              style="
                font-size: 12px !important;
                transform: scale(0.8) translate(0, -5px);
                color: #08ae9e;
              "
            >
              <p>合相</p>
              <span>功率因数</span>
            </div>
          </div>
        </div>
        <div style="padding-top: 35px; padding-left: 10px">
          <span class="circle" style="background-color: #088fef"></span>
        </div>
        <div style="margin: 0 10px; padding-top: 30px">
          <div style="margin-bottom: 10px">有功功率</div>
          <div>无功功率</div>
        </div>
        <div style="padding-top: 25px" class="relative">
          <el-progress
            v-if="progressShow"
            :class="{ rotate: progressData.H[0] < 0 }"
            :percentage="
              format(Math.abs((progressData.H[0] / progressData.H[2]) * 100))
            "
            color="#088fef"
            :stroke-width="6"
            style="width: 100px; margin-bottom: 22px"
            :show-text="false"
          ></el-progress>
          <el-progress
            v-if="progressShow"
            :class="{ rotate: progressData.H[1] < 0 }"
            :percentage="
              format(Math.abs((progressData.H[1] / progressData.H[2]) * 100))
            "
            color="#088fef"
            :stroke-width="6"
            style="width: 100px"
            :show-text="false"
          ></el-progress>
          <span class="span_absolute color_bar4">{{ progressData.H[0] }}</span>
          <span class="span2_absolute color_bar4">{{ progressData.H[1] }}</span>
        </div>
        <div style="padding-top: 28px; text-align: center">
          <div style="margin-bottom: 10px">视在功率</div>
          <div class="text_color">{{ progressData.H[2] }}</div>
        </div>
      </div>
    </div>
    <!-- 电能 -->
    <div style="height: 180px; width: 100%">
      <div
        style="
          font-weight: 700;
          text-align: left;
          margin-left: 20px;
          color: #000;
        "
      >
        电能
      </div>
      <div
        style="
          display: flex;
          color: black;
          font-size: 14px;
          margin-top: 20px;
          padding: 0 10px;
          justify-content: space-around;
        "
      >
        <div><span class="circle"></span></div>
        <div style="margin: 0 10px; white-space: nowrap">A相</div>
        <div class="energy" style="position: relative">
          <el-progress
            v-if="progressShow"
            :show-text="false"
            :percentage="
              format(Math.abs((energyData.A[0] / energyData.A[1]) * 100))
            "
            color="#f36710"
            :stroke-width="8"
            style="width: 180px"
          ></el-progress>
          <span
            style="position: absolute; left: 15px; top: -16px; font-weight: 700"
            >{{ energyData.A[0] }}</span
          >
        </div>
        <div style="margin-top: -15px; text-align: center">
          视在电能<br /><span class="text_color">{{ energyData.A[1] }}</span>
        </div>
      </div>
      <div
        style="
          display: flex;
          color: black;
          font-size: 14px;
          margin-top: 20px;
          padding: 0 10px;
          justify-content: space-around;
        "
      >
        <div>
          <span class="circle" style="background-color: #66c603"></span>
        </div>
        <div style="margin: 0 10px; white-space: nowrap">B相</div>
        <div class="energy" style="position: relative">
          <el-progress
            v-if="progressShow"
            :show-text="false"
            :percentage="
              format(Math.abs((energyData.B[0] / energyData.B[1]) * 100))
            "
            color="#66c603"
            :stroke-width="8"
            style="width: 180px"
          ></el-progress>
          <span
            style="position: absolute; left: 15px; top: -16px; font-weight: 700"
            >{{ energyData.B[0] }}</span
          >
        </div>
        <div style="margin-top: -15px; text-align: center">
          视在电能<br /><span class="text_color">{{ energyData.B[1] }}</span>
        </div>
      </div>
      <div
        style="
          display: flex;
          color: black;
          font-size: 14px;
          margin-top: 20px;
          padding: 0 10px;
          justify-content: space-around;
        "
      >
        <div>
          <span class="circle" style="background-color: #fd4848"></span>
        </div>
        <div style="margin: 0 10px; white-space: nowrap">C相</div>
        <div class="energy" style="position: relative">
          <el-progress
            v-if="progressShow"
            :show-text="false"
            :percentage="
              format(Math.abs((energyData.C[0] / energyData.C[1]) * 100))
            "
            color="#fd4848"
            :stroke-width="8"
            style="width: 180px"
          ></el-progress>
          <span
            style="position: absolute; left: 15px; top: -16px; font-weight: 700"
            >{{ energyData.C[0] }}</span
          >
        </div>
        <div style="margin-top: -15px; text-align: center">
          视在电能<br /><span class="text_color">{{ energyData.C[1] }}</span>
        </div>
      </div>
      <div
        style="
          display: flex;
          color: black;
          font-size: 14px;
          margin-top: 20px;
          padding: 0 10px;
          justify-content: space-around;
        "
      >
        <div>
          <span class="circle" style="background-color: #088fef"></span>
        </div>
        <div style="margin: 0 10px; white-space: nowrap">合相</div>
        <div class="energy" style="position: relative">
          <el-progress
            v-if="progressShow"
            :show-text="false"
            :percentage="
              format(Math.abs((energyData.H[0] / energyData.H[1]) * 100))
            "
            color="#088fef"
            :stroke-width="8"
            style="width: 180px"
          ></el-progress>
          <span
            style="position: absolute; left: 15px; top: -16px; font-weight: 700"
            >{{ energyData.H[0] }}</span
          >
        </div>
        <div style="margin-top: -15px; text-align: center">
          视在电能<br /><span class="text_color">{{ energyData.H[1] }}</span>
        </div>
      </div>
    </div>
    <!-- 相位角 -->
    <!-- <div class="relative" style="width: 100%; height: 210px">
      <span class="absolute" style="font-weight: 700">相位角</span>
      <div style="height: 100%; display: flex">
        <div style="width: 50%; height: 100%" id="pieVoltage"></div>
        <div style="width: 50%; height: 100%" id="pieElectricity"></div>
      </div>
    </div> -->
    <!-- 温度 -->
    <div class="relative" style="width: 100%; height: 250px">
      <span class="absolute" style="font-weight: 700"
        >{{ titleDropdown
        }}<van-icon
          name="setting-o"
          style="margin-left: 10px"
          @click="show = true"
          color="#5470c6"
      /></span>
      <div id="temperature" style="height: 100%; width: 100%"></div>
    </div>
    <van-popup
      v-model="show"
      round
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-picker
        title="实时数据"
        show-toolbar
        :columns="powerList"
        @confirm="realTimeData"
        @cancel="show = false"
        :default-index="11"
      />
    </van-popup>
    <van-popup
      v-model="sheetShow"
      round
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-picker
        title="均极值曲线"
        show-toolbar
        :columns="CurveData"
        @confirm="getPowerFactor($event.id)"
        @cancel="show = false"
        :default-index="3"
      />
    </van-popup>
    <EneryHistory :history.sync="history" v-if="history" />
  </div>
</template>

<script>
let that;
import * as echarts from "echarts";
import EneryHistory from "./EneryHistory.vue";
import { powerParamSelectNew } from "../util/constant";
export default {
  components: {
    EneryHistory,
  },
  data() {
    return {
      timer: null,
      history: false,
      maxDate: new Date(),
      PowerFactorData: [],
      showTime: false,
      currentDate: new Date(),
      powerDate: [],
      Dropdownpower: "",
      paramsData: {
        UA: "",
        UB: "",
        UC: "",
        UAB: "",
        UBC: "",
        UAC: "",
        IA: "",
        IB: "",
        IC: "",
      },
      realData: {
        DE: "",
        DOT: "",
        DRT: "",
        EEF: "",
        IAV: "",
        IMA: "",
        IMI: "",
        IOR: "",
        ME: "",
        MMP: "",
        MOT: "",
        MRT: "",
        PAV: "",
        PEF: "",
        PMA: "",
        PMI: "",
        TAV: "",
        TMA: "",
        TMI: "",
        UAV: "",
        UMA: "",
        UMI: "",
      },
      sheetShow: false,
      show: false,
      title: this.$route.query.pjName,
      progressShow: false,
      contrast: {},
      titleDropdown: "温度",
      zoom: 1,
      electricity: true,
      voltage: true,
      progressData: {
        A: [],
        B: [],
        C: [],
        H: [],
      },
      energyData: {
        A: [],
        B: [],
        C: [],
        H: [],
      },
      CurveData: [
        { id: 1, text: "电压", paramTag: ["UMA", "UAV", "UMI"] }, //电压
        { id: 2, text: "电流", paramTag: ["IMA", "IAV", "IMI"] }, //电流
        { id: 3, text: "功率", paramTag: ["PMA", "PAV", "PMI"] }, //功率
        { id: 4, text: "温度", paramTag: ["TMA", "TAV", "TMI"] }, //温度
      ],
      powerList: powerParamSelectNew,
      paramFrom: {
        projectId: this.$route.query.projectId,
        paramTags: [],
      },
      setBar(data, xData, ref) {
        let option = {
          tooltip: {
            trigger: "axis",
          },
          xAxis: {
            type: "category",
            data: xData,
            axisLabel: { interval: 0, rotate: 20 },
          },
          grid: {
            y: 100,
            y2: 100,
            x: 100,
            x2: 100,
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: data,
              type: "bar",
              itemStyle: {
                color: "rgb(43,194,209)",
              },
            },
          ],
        };
        let chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      setHistory(title, xData, yData, ref) {
        let option = {
          tooltip: {
            trigger: "axis",
            extraCssText: "z-index:999999",
            confine: true,
          },
          legend: {
            color: "#000",
            right: "5%",
            top: "3%",
            orient: "vertical",
            icon: "circle",
            itemStyle: {
              textColor: "#000",
            },
          },
          grid: {
            left: "8%",
            top: "5%",
            bottom: "20%",
            right: "5%",
          },
          xAxis: {
            data: xData,
            type: "category",
            boundaryGap: true,
            axisLabel: {
              inside: false,
              color: "#000",
              // formatter: function (value) {
              //   if (value.length > 8) {
              //     return `${value.slice(0, 8)}...`;
              //   }
              //   return value;
              // },
            },
            axisLine: {
              lineStyle: {
                color: "#000",
              },
            },
          },
          yAxis: {
            type: "value",
            nameLocation: "end",
            boundaryGap: [0, 0],
            axisLine: {
              show: true,
              lineStyle: {
                color: "#000",
              },
            },
            axisLabel: {
              inside: false,
              color: "#000",
            },
            splitLine: {
              lineStyle: {
                color: "#000",
                width: 0.8,
                type: "dashed",
              },
            },
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
            },
          ],
          // series: data,
          series: [
            {
              smooth: true,
              name: title[0],
              type: "line",
              itemStyle: {
                color: "#ecbf18",
              },
              data: yData[0],
            },
            {
              smooth: true,
              name: title[1],
              type: "line",
              itemStyle: {
                color: "#19c572",
              },
              data: yData[1],
            },
            {
              smooth: true,
              name: title[2],
              type: "line",
              itemStyle: {
                color: "#e01428",
              },
              data: yData[2],
            },
          ],
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      setPie(data, ref, title) {
        let option = {
          title: {
            text: title,
            left: "center",
            bottom: "10%",
            textStyle: {
              color: "#13b1a2",
              fontSize: "14px",
            },
          },
          series: [
            {
              silent: true,
              type: "pie",
              radius: "60%",
              hoverAnimation: false,
              label: {
                position: "inside",
              },
              labelLayout: (params) => {
                let x = "";
                let y = "";
                if (params.dataIndex == 0) {
                  x = params.rect.x + 8;
                  y = params.rect.y - 5;
                } else if (params.dataIndex == 1) {
                  x = params.rect.x + 130;
                  y = params.rect.y + 35;
                } else if (params.dataIndex == 2) {
                  x = params.rect.x + 8;
                  y = params.rect.y + 90;
                }

                return {
                  x: x,
                  y: y,
                  verticalAlign: "middle",
                  align: "right",
                };
              },
              data: [
                { value: 1, name: "0°" },
                { value: 1, name: "120°" },
                { value: 1, name: "240°" },
              ],
              z: 3,
              itemStyle: {
                // 间隔
                borderWidth: 2,
                borderColor: "#fff",
                color: "none",
              },
            },
            {
              color: ["#24e5b0"],
              type: "pie",
              silent: true,
              // minAngle: 15,//最小角度
              // startAngle:270, //起始角度
              backgroundColor: "", //设置无背景色
              radius: "60%",
              hoverAnimation: false,
              label: {
                show: false,
              },
              z: 1,
              data: data,
              itemStyle: {
                // 间隔
                borderWidth: 1,
                borderColor: "#000",
                type: "dashed",
                borderType: [1, 5],
                borderDashOffset: 5,
              },
            },
          ],
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },

      setBarw(xdata, ydata, ref) {
        let option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            top: "15%",
            left: "3%",
            right: "4%",
            bottom: "20%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: xdata,
              axisLabel: {
                interval: 0,
                // rotate:-20
              },
              axisTick: {
                alignWithLabel: true,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLine: {
                show: true,
              },
            },
          ],
          series: [
            {
              name: "",
              type: "bar",
              barWidth: "40%",
              data: ydata,
            },
          ],
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        // myChart.clear();
        myChart.setOption(option);
      },
    };
  },
  created() {
    that = this;
    this.getPower();
    // let HalfMoon = this.dayjs(this.dayjs().subtract(7, "day").$d).format(
    //   "YYYY-MM-DD"
    // ); //7天前
    // that.currentDate = this.dayjs().format("YYYY-MM-DD");
  },
  methods: {
    onClickRight() {
      this.history = true;
    },
    realTimeData(val) {
      this.handleCommand(val);
    },
    format(val) {
      return val > 100 ? 100 : val;
    },
    async handleCommand(val) {
      that.titleDropdown = val.text;
      that.paramFrom.paramTags = val.params;
      //   that.singleFrom.tag = 'D435';
      //   that.singleFrom.value = val.id;
      // await  that.singleCmds();
      that.$api.energy.getParams(that.paramFrom).then((res) => {
        if (res.code == 200) {
          let xData = [];
          let yData = [];
          res.data.forEach((item) => {
            xData.push(item.paramName);
            yData.push(item.value);
          });
          that.setBarw(xData, yData, "temperature");
          that.show = false;
        }
      });
    },
    //因数温度
    getPowerFactor(index) {
      that.sheetShow = false;
      that.showTime = false;
      if (index) {
        that.CurveData.forEach((item) => {
          if (item.id == index) {
            this.PowerFactorData = item.paramTag;
            this.Dropdownpower = item.text;
          }
        });
      }
      let from = {
        // startDate: that.powerDate[0],
        date: this.dayjs(that.currentDate).format("YYYY-MM-DD"),
        projectId: this.$route.query.projectId * 1,
        paramTags: this.PowerFactorData,
        historyType: 1,
      };
      this.$api.energy.getHistoryData(from).then((res) => {
        if (res.code == 200) {
          let title = [];
          let xData = res.data.date;
          let yData = [];
          res.data.data.forEach((item) => {
            let newData = [];
            for (let key in item.stream) {
              newData.push([key, item.stream[key]]);
            }
            title.push(item.name);
            yData.push(newData);
          });
          that.setHistory(title, xData, yData, "power");
        }
      });
    },
    // 获取电能
    getenergy() {
      let from = {
        projectId: this.$route.query.projectId,
        paramTags: ["EPA", "EPB", "EPC", "EPT","ESA", "ESB", "ESC", "EST"],
      };
      that.$api.energy.getParams(from).then((res) => {
        if (res.code == 200) {
          let A = ["EPA", "ESA"];
          let B = ["EPB", "ESB"];
          let C = ["EPC", "ESC"];
          let H = ["EPT", "EST"];
          res.data.forEach((item) => {
            if (A.includes(item.paramTag)) {
              that.energyData.A.push(item.value);
            } else if (B.includes(item.paramTag)) {
              that.energyData.B.push(item.value);
            } else if (C.includes(item.paramTag)) {
              that.energyData.C.push(item.value);
            } else if (H.includes(item.paramTag)) {
              that.energyData.H.push(item.value);
            }
          });
        }
      });
    },
    getRealData() {
      let from = {
        projectId: this.$route.query.projectId * 1,
        paramTags: [
          "MMP",
          "DRT",
          "MRT",
          "IOR",
          "PEF",
          "DOT",
          "MOT",
          "EEF",
          "UMA",
          "UMI",
          "UAV",
          "IMA",
          "IMI",
          "IAV",
          "TMA",
          "TMI",
          "TAV",
          "PMA",
          "PMI",
          "PAV",
          "DE",
          "ME",
        ],
      };
      this.$api.energy.getParams(from).then((res) => {
        this.timer = setTimeout(() => {
          that.getRealData();
          that.getcontrast();
          // that.getPhaseAngle();
          that.getenergy();
        }, 10000);
        if (res.code == 200) {
          res.data.forEach((item) => {
            this.realData[item.paramTag] = item.value;
          });
        }
      });
    },
    //相位角
    getPhaseAngle() {
      let from = {
        projectId: this.$route.query.projectId,
        paramTags: [
          "YIA", //A电
          "YIB",
          "YIC",
          "YUA", //A压
          "YUB",
          "YUC",
        ],
      };
      that.$api.energy.getParams(from).then((res) => {
        if (res.code == 200) {
          let obj = {};
          let data = [];
          let data1 = [];
          res.data.forEach((item) => {
            obj[item.paramTag] = item.value;
          });
          //电压
          // let a1 = (obj['D53YUB'] /120).toFixed(2)
          // let b1 = (obj['D54YUC'] / 240).toFixed(2)
          if (obj["YUA"] == 0 && obj["YUB"] == 0 && obj["YUC"] == 0) {
            data = [
              { value: 0, name: "0°" },
              { value: 0, name: "120°" },
              { value: 0, name: "240°" },
            ];
          } else {
            let a = ((obj["YUB"] * 1) / 120).toFixed(3) * 1;
            let b =
              (((obj["YUC"] * 1 - 120) / 120).toFixed(3) * 10 + (1 - a) * 10) /
              10;
            let num = null;
            if (obj["YUA"] == 0 || obj["YUA"] < 5) {
              num = 0;
            } else {
              num = ((360 - obj["YUA"] * 1) / 120).toFixed(3) * 10;
            }
            let c = (num + ((obj["YUC"] * 1 - 120) / 120).toFixed(3) * 10) / 10;
            let d = 3 - a - b - c;
            data = [
              { value: a, name: "0°" },
              { value: b, name: "120°" },
              { value: c, name: "240°" },
              { value: d, name: "240°" },
            ];
          }
          //电流
          if (obj["YIA"] == 0 && obj["YIB"] == 0 && obj["YIC"] == 0) {
            data1 = [
              { value: 0, name: "0°" },
              { value: 0, name: "120°" },
              { value: 0, name: "240°" },
            ];
          } else {
            let a = (obj["YIB"] / 120).toFixed(3);
            let b =
              (((obj["YIC"] * 1 - 120) / 120).toFixed(3) * 10 + (1 - a) * 10) /
              10;
            let num = null;
            if (obj["YIA"] == 0 || obj["YIA"] < 5) {
              num = 0;
            } else {
              num = ((360 - obj["YIA"] * 1) / 120).toFixed(3) * 10;
            }
            let c = (num + ((obj["YIB"] * 1 - 120) / 120).toFixed(3) * 10) / 10;
            let d = 3 - a - b - c;
            data1 = [
              { value: a, name: "0°" },
              { value: b, name: "120°" },
              { value: c, name: "240°" },
              { value: d, name: "240°" },
            ];
          }
          this.$nextTick(() => {
            that.setPie(data1, "pieElectricity", "电流");
            that.setPie(data, "pieVoltage", "电压");
          });
        }
      });
    },
    //实时数据
    getcontrast() {
      let from = {
        projectId: this.$route.query.projectId,
        paramTags: Object.keys(this.paramsData),
      };
      that.$api.energy.getParams(from).then((res) => {
        if (res.code == 200) {
          res.data.forEach((item) => {
            this.paramsData[item.paramTag] = item.value;
          });
        }
      });
    },
    // 获取功率因素
    getPower() {
      let from = {
        projectId: this.$route.query.projectId,
        paramTags: [
          "PA",
          "PB",
          "PC",
          "PT",
          "QA",
          "QB",
          "QC",
          "QT",
          "SA",
          "SB",
          "SC",
          "ST",
          "FA",
          "FB",
          "FC",
          "FT",
        ],
      };
      that.$api.energy.getParams(from).then((res) => {
        if (res.code == 200) {
          let A = ["PA", "QA", "SA", "FA"];
          let B = ["PB", "QB", "SB", "FB"];
          let C = ["PC", "QC", "SC", "FC"];
          let H = ["PT", "QT", "ST", "FT"];
          res.data.forEach((item) => {
            if (A.includes(item.paramTag)) {
              that.progressData.A.push(item.value);
            } else if (B.includes(item.paramTag)) {
              that.progressData.B.push(item.value);
            } else if (C.includes(item.paramTag)) {
              that.progressData.C.push(item.value);
            } else if (H.includes(item.paramTag)) {
              that.progressData.H.push(item.value);
            }
          });
          setTimeout(() => {
            that.progressShow = true;
          }, 100);
        }
      });
    },
  },
  beforeDestroy() {
    clearTimeout(that.timer);
    that.timer = null;
  },
  mounted() {
    this.zoom = 1 / document.body.style.zoom;
    that.getRealData();
    that.getenergy();
    // that.getPhaseAngle();
    that.getPowerFactor(4);
    that.getcontrast();
    that.handleCommand({
      text: "温度",
      id: "11",
      params: ["TA", "TB", "TC", "TN", "TE"],
    });
  },
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
}
.relative {
  position: relative;
  margin: 10px 5px;
}
.absolute {
  position: absolute;
  left: 20px;
  color: #000;
  z-index: 999;
}
.big {
  font-size: 16px;
  font-weight: 700;
}
.energy {
  padding-top: 5px;
  color: #f36710;
}
.energy ::v-deep .el-progress-bar__outer {
  width: 170px;
}
.color_bar1 {
  color: #f36710;
}
.color_bar2 {
  color: #66c603;
}
.color_bar3 {
  color: #fd4848;
}
.color_bar4 {
  color: #088fef;
}
.text_color {
  color: #959595;
  font-weight: 700;
}
.Page_heading {
  height: 80px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.Page_heading > div {
  font-weight: 700;
  width: 33%;
  height: 30%;
  line-height: 2;
}
.pie_absolute {
  position: absolute;
  top: 22px;
  left: 20px;
}
.Dropdownbox {
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
}
.Dropdownbox::-webkit-scrollbar {
  display: none;
}
.rotate {
  transform: rotate(180deg) translate(10px, 0);
}
/* 隐藏 Chrome、Safari 和 Opera 的滚动条 */
::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* 隐藏 IE、Edge 和 Firefox 的滚动条 */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f36710;
}
.span_absolute {
  position: absolute;
  top: 5px;
  left: 0;
}
.span2_absolute {
  position: absolute;
  top: 35px;
  left: 0;
}
.Panel {
  width: 95%;
  height: 100%;
  margin: 0 10px;
  background-color: #3980fd;
}
/* .box_item1 .box {
  background-color: #fff;
  padding: 10px 10px;
} */
.Panel_item {
  text-align: center !important;
  height: 24%;
  width: 94%;
  padding-right: 20px;
  line-height: 3;
  border-bottom: 1px solid #fff;
  color: #fff;
}
.Panel_item span {
  display: inline-block;
  width: 20%;
}
/* @import url(); 引入css类 */
</style>
